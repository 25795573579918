import React from 'react';
import { useInView } from 'react-intersection-observer';
import servicoss from '../assets/images/servicos-image.png';
import logoApple from '../assets/images/logo-apple.png';
import logoHp from '../assets/images/logo-hp.png';
import logoLenovo from '../assets/images/logo-lenovo.png';
import logoDell from '../assets/images/logo-dell.png';
import logoSony from '../assets/images/logo-sony.png';
import logoJbl from '../assets/images/logo-jbl.png';
import logoHarman from '../assets/images/logo-harman.png';
import logoPioneer from '../assets/images/logo-pioneer.png';

const Servicos = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // 10% visível
  });

  return (
    <section id="servicos" ref={ref} className={`min-h-screen flex flex-col justify-center items-center p-4 bg-white transition-opacity duration-1000 ${inView ? 'opacity-100' : 'opacity-0'}`}>
      <div className="mt-24 flex flex-col items-center w-full">
        <img src={servicoss} alt="Servicos Image" className="mb-4 w-full max-w-4xl" />
        <p className="text-xl font-semibold text-center">Se é autorizado, é qualidade!</p>
        <p className="text-gray-700 text-center">Centro autorizado de várias marcas.</p>
        <p className="text-gray-700 text-center mb-8">A assistência que você precisa!</p>
        <div className="mt-8 w-full">
          <p className="font-semibold text-center">Marcas atendidas:</p>
          <div className="flex flex-wrap justify-center space-x-6 mt-8">
            <img src={logoApple} alt="Apple" className="h-16" />
            <img src={logoHp} alt="HP" className="h-16" />
            <img src={logoLenovo} alt="Lenovo" className="h-16" />
            <img src={logoDell} alt="Dell" className="h-16" />
            <img src={logoSony} alt="Sony" className="h-16" />
            <img src={logoJbl} alt="JBL" className="h-16" />
            <img src={logoHarman} alt="Harman Kardon" className="h-16" />
            <img src={logoPioneer} alt="Pioneer" className="h-16" />
          </div>
        </div>
        <a 
          href="https://wa.me/5511992234488" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="mt-6 mb-20 bg-blue-500 text-white py-6 px-6 rounded-full hover:bg-blue-600 transition duration-300 ease-in-out"
        >
          Entre em contato
        </a>
      </div>
    </section>
  );
};

export default Servicos;
