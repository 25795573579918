import React from 'react';
import produtoImage from '../assets/images/produtos.jpg'; // Substitua pelo caminho da sua imagem

const EnvioProdutos = () => {
  return (
    <div className="mt-24 min-h-screen flex flex-col">
      <section id="envio-produtos" className="p-4 bg-gray-200 flex-grow">
        <h2 className="text-2xl font-bold text-center mb-4">Envio de Produtos</h2>
        <div className="flex flex-col items-center mt-4">
          <div className="w-full max-w-4xl flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
            <img src={produtoImage} alt="Envio de Produtos" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="bg-white p-6 rounded-lg shadow-lg text-center md:text-left">
              <h3 className="text-lg font-semibold text-blue-600">Envio de Produtos</h3>
              <p className="mt-2">Atendemos o Brasil inteiro, através de transportadoras. Contudo, é necessário que entre em contato conosco.</p>
              <p className="mt-2 text-lg font-semibold text-blue-600">A TSI possui peças originais dos fabricantes para vendas</p>
              <p className="mt-2">Entre em contato conosco para confirmar disponibilidade de estoque e/ou previsão de entrega e como comprar.</p>
            </div>
          </div>
          <h3 className="mt-48 text-xl font-bold text-center mt-16">Nossos Canais de Atendimento:</h3>
          <hr className="border-blue-600 w-24 my-4" />
          <div className="w-full max-w-4xl flex flex-col md:flex-row justify-around text-center md:text-left space-y-4 md:space-y-0 mb-8">
            <div>
              <h4 className="font-semibold">Loja Física</h4>
              <p>Rua dos Pinheiros 382 - Pinheiros - São Paulo - S.P.</p>
            </div>
            <div>
              <h4 className="font-semibold">Atendimento Online</h4>
              <p>+55 11 99223-4488</p>
              <p>Atendimento via WhatsApp</p>
              <p>Horário Comercial: 08:30 - 18:00 de Seg-Sex</p>
            </div>
            <div>
              <h4 className="font-semibold">E-mail</h4>
              <p>tsi@tsi-informatica.com.br</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EnvioProdutos;
