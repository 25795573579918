import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleNavigationClick = (e, hash) => {
    if (location.pathname === '/envio-produtos') {
      e.preventDefault();
      navigate(`/#${hash}`);
    }
  };

  const controlHeader = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) { // if scroll down
        setShowHeader(false);
      } else { // if scroll up
        setShowHeader(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeader);

      return () => {
        window.removeEventListener('scroll', controlHeader);
      };
    }
  }, [lastScrollY]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={`bg-gray-200 py-4 fixed w-full top-0 z-50 transition-transform duration-300 ${showHeader ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="container mx-auto flex items-center justify-between px-4">
        <div className="flex items-center">
          <img src={logo} alt="Logo TSI Informática" className="h-16 mr-4 cursor-pointer" onClick={(e) => handleNavigationClick(e, 'home')} />
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-black focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
            </svg>
          </button>
        </div>
        <nav className={`lg:flex lg:items-center lg:space-x-12 ${isOpen ? 'block' : 'hidden'} lg:block`}>
          <a href="#home" onClick={(e) => handleNavigationClick(e, 'home')} className="block mt-4 lg:inline-block lg:mt-0 text-black text-lg hover:text-gray-600">HOME</a>
          <a href="#servicos" onClick={(e) => handleNavigationClick(e, 'servicos')} className="block mt-4 lg:inline-block lg:mt-0 text-black text-lg hover:text-gray-600">SERVIÇOS</a>
          <a href="#nossa-historia" onClick={(e) => handleNavigationClick(e, 'nossa-historia')} className="block mt-4 lg:inline-block lg:mt-0 text-black text-lg hover:text-gray-600">NOSSA HISTÓRIA</a>
          <a href="#localizacao" onClick={(e) => handleNavigationClick(e, 'localizacao')} className="block mt-4 lg:inline-block lg:mt-0 text-black text-lg hover:text-gray-600">LOCALIZAÇÃO</a>
          <Link to="/envio-produtos" className="block mt-4 lg:inline-block lg:mt-0 text-black text-lg hover:text-gray-600">ENVIO DE PRODUTOS</Link>
          <a href="https://wa.me/5511992234488" target="_blank" rel="noopener noreferrer" className="block mt-4 lg:inline-block lg:mt-0 text-black text-lg hover:text-gray-600">CONTATO</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
