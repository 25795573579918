import React from 'react';

const Home = () => {
  return (
    <section id="home" className="h-screen flex flex-col justify-center items-center p-4 bg-white">
      <div className="flex justify-center w-full" data-aos="fade-up">
        <video
          className="w-full max-w-6xl rounded-lg"
          controls
        >
          <source src="/videos/TSI-video.mp4" type="video/mp4" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      </div>
      <div className="text-center mb-2 mt-4" data-aos="fade-up" data-aos-delay="200">
        <p className="italic">Cuidamos do seu equipamento, como se fosse nosso.</p>
      </div>
    </section>
  );
};

export default Home;
