import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Servicos from './pages/Servicos';
import NossaHistoria from './pages/NossaHistoria';
import Localizacao from './pages/Localizacao';
import EnvioProdutos from './pages/EnvioProdutos';
import './index.css';

function MainPage() {
  return (
    <div>
      <Servicos />
      <Home />
      <NossaHistoria />
      <Localizacao />
    </div>
  );
}

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Aumentar a duração da animação
      once: true, // Garantir que a animação ocorra apenas uma vez
    });
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/envio-produtos" element={<EnvioProdutos />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
