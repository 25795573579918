import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import casa from '../assets/images/casa.jpg';

const Localizacao = () => {
  const position = [-23.5650756,-46.6820926]; // Coordenadas para a localização desejada

  return (
    <section id="localizacao" className="p-4 bg-gray-200">
      <h2 className="text-2xl font-bold text-center">Endereço:</h2>
      <p className="text-center mt-2">Rua dos Pinheiros, 382 - Pinheiros, São Paulo</p>
      <p className="text-center">05422 - 010</p>
      <div className="flex flex-col items-center mt-4">
        <div className="w-full max-w-4xl flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1 w-full">
            <MapContainer center={position} zoom={20} style={{ height: '400px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={position}>
                <Popup>
                  TSI Informática <br /> Rua dos Pinheiros, 382.
                </Popup>
              </Marker>
            </MapContainer>
          </div>
          <div className="flex-1 w-full">
          <img src={casa} alt="TSI Informática" className="w-full h-[400px] object-cover rounded-lg" />
          </div>
        </div>
        <p className="text-center mt-4">Abertos de Segunda á Sexta<br />9:00 às 18:00</p>
      </div>
    </section>
  );
};

export default Localizacao;
