import React from 'react';
import { useInView } from 'react-intersection-observer';
import historiaImage from '../assets/images/historia.jpg';

const NossaHistoria = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // 10% visível
  });

  return (
    <section id="nossa-historia" ref={ref} className={`min-h-screen p-8 transition-opacity duration-1000 ${inView ? 'opacity-100' : 'opacity-0'}`} data-aos="fade-up">
      <h2 className="text-3xl font-bold text-center mb-8">Quem Somos</h2>
      <div className="flex flex-col md:flex-row items-center md:items-start">
        <div className="w-full md:w-1/2 p-4">
          <img src={historiaImage} alt="Nossa História" className="w-full rounded-lg shadow-lg" />
          <p className="text-center mt-4 italic">TSI Informática: A solução completa para o reparo de seus eletrônicos.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg text-center md:text-left w-full md:w-1/2 p-4">
          <p className="text-lg">
            Com mais de 30 anos de experiência no mercado, a TSI Informática se consolidou como referência em reparo de eletrônicos, atendendo a um grande número de clientes em todo o Brasil, tanto pessoas físicas quanto jurídicas.
          </p>
          <p className="text-lg mt-4">
            Fundada em 1990, a empresa se dedica à prestação de serviços e pós-venda de alta qualidade, contando com a supervisão de engenheiros eletrônicos experientes e certificados pelas principais marcas do mercado.
          </p>
          <p className="text-lg mt-4">
            Somos um posto de serviço autorizado para diversas marcas renomadas, como Sony, HP, Samsung, Harman Kardon, JBL, PionnerDJ, além de sermos especializados em Dell, Apple e Lenovo. Isso significa que você pode ter a certeza de que seus equipamentos serão reparados com peças originais e por profissionais altamente qualificados.
          </p>
          <p className="text-lg mt-4">
            Nossa equipe de técnicos experientes está constantemente atualizada sobre as últimas tecnologias e utiliza equipamentos de última geração para garantir um diagnóstico preciso e um reparo rápido e eficiente.
          </p>
          <p className="text-lg mt-4">
            Contamos com uma unidade estrategicamente localizada no bairro de Pinheiros em São Paulo, com um total de 1.000 m², dos quais 900 m² são destinados a 4 modernos laboratórios.
          </p>
          <p className="text-lg mt-4">
            Oferecemos fácil acesso, estacionamento gratuito e um ambiente confortável para nossos clientes. Além disso, contamos com uma excelente estrutura para logística de grandes volumes, o que nos permite atender empresas e instituições de todos os portes.
          </p>
          <p className="text-lg mt-4">
            Nosso compromisso é oferecer um atendimento personalizado e de alta qualidade a todos os nossos clientes. Acreditamos que a confiança e a satisfação dos nossos clientes são os pilares do nosso sucesso.
          </p>
        </div>
      </div>
    </section>
  );
};

export default NossaHistoria;
